const PrevRoundIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.3667 1.85L7.75 8.63333C6.81667 9.3 6.81667 10.7 7.75 11.35L17.3667 18.15C18.4833 18.9167 20 18.1333 20 16.7833L20 3.21667C20 1.86667 18.4833 1.08333 17.3667 1.85ZM3.33333 18.3333L3.33334 1.66667C3.33334 0.749999 2.58334 -1.52261e-06 1.66667 -1.60275e-06C0.750002 -1.68289e-06 1.68289e-06 0.749998 1.60275e-06 1.66667L1.45705e-07 18.3333C6.55671e-08 19.25 0.75 20 1.66667 20C2.58333 20 3.33333 19.25 3.33333 18.3333Z"
            fill="white"
        />
    </svg>
);

export default PrevRoundIcon;
