import AppConstants from "../constants";
import { suits } from "../../assets/icons";
import { store } from "../store/store";

const {
    cardSuits,
    dimensions: {
        DEFAULT_ASPECT_RATIO_HEIGHT_MOBILE,
        DEFAULT_ASPECT_RATIO_WIDTH_MOBILE,
        DEFAULT_ASPECT_RATIO_HEIGHT_DESKTOP,
        DEFAULT_ASPECT_RATIO_WIDTH_DESKTOP,
        FONT_SIZE_SCALE_INDEX_DESKTOP,
        FONT_SIZE_SCALE_INDEX_LANDSCAPE,
        FONT_SIZE_SCALE_INDEX_PORTRAIT
    },
    shortKeys
} = AppConstants;

const gameResolutionMobile = DEFAULT_ASPECT_RATIO_WIDTH_MOBILE / DEFAULT_ASPECT_RATIO_HEIGHT_MOBILE;
const gameResolutionDesktop = DEFAULT_ASPECT_RATIO_WIDTH_DESKTOP / DEFAULT_ASPECT_RATIO_HEIGHT_DESKTOP;

export const urlParams = (() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { isMobile } = params;

    params.isMobile = (isMobile && isMobile.toLowerCase()) === "true" || false;
    params.isHistory = window.location?.pathname?.includes("/game/history");
    return params;
})();

export const translate = (() => {
    let translationState = null;
    return {
        t: key => {
            if (!translationState) {
                translationState = store.getState().translationState.resources;
            }
            return (translationState && translationState[key]) || key;
        }
    };
})().t;

const setRootFontSize = (gameWidth, gameHeight, isMobile, isPortrait) => {
    const rootElement = document.querySelector("html");
    const mobileSizeIndex = isPortrait ? FONT_SIZE_SCALE_INDEX_PORTRAIT : FONT_SIZE_SCALE_INDEX_LANDSCAPE;
    const sizeIndex = isMobile ? mobileSizeIndex : FONT_SIZE_SCALE_INDEX_DESKTOP;

    const demissionPortrait = gameWidth / gameHeight;
    const fontSizeDemission = gameWidth / sizeIndex;

    const calcDemissionForPortrait = isPortrait && demissionPortrait > 0.7;
    const calcDem = fontSizeDemission - demissionPortrait;
    const calcDemForWidth = calcDem > 0.9 ? calcDem : 0.9;
    const fontSize = `${calcDemissionForPortrait && gameWidth > 350 ? calcDemForWidth : fontSizeDemission}px`;

    rootElement.style.fontSize = fontSize;
};

export const calculateGameDimension = (clientWidth, clientHeight) => {
    const { isMobile } = urlParams;

    const isPortrait = isMobile && clientWidth < clientHeight;
    const isFullWidth = clientWidth / clientHeight < gameResolutionDesktop;
    const dimensionsConfig = {
        gameWidth: isFullWidth ? clientWidth : clientHeight * gameResolutionDesktop,
        gameHeight: !isFullWidth ? clientHeight : clientWidth / gameResolutionDesktop
    };

    dimensionsConfig.fontSize = dimensionsConfig.gameWidth / FONT_SIZE_SCALE_INDEX_LANDSCAPE;

    if (isMobile) {
        if (isPortrait) {
            const isFullHeight = clientHeight / clientWidth < gameResolutionMobile;

            dimensionsConfig.gameWidth = isFullHeight ? clientWidth : clientHeight / gameResolutionMobile;
            dimensionsConfig.gameHeight = isFullHeight ? clientHeight : clientWidth * gameResolutionMobile;
        } else {
            const isMobileFullWidth = clientWidth / clientHeight < gameResolutionMobile;

            dimensionsConfig.gameWidth = isMobileFullWidth ? clientWidth : clientHeight * gameResolutionMobile;
            dimensionsConfig.gameHeight = isMobileFullWidth ? clientHeight : clientWidth / gameResolutionMobile;
        }
    }

    setRootFontSize(dimensionsConfig.gameWidth, dimensionsConfig.gameHeight, isMobile, isPortrait);
    return { ...dimensionsConfig, clientWidth, clientHeight };
};

export const getCardPositionCalcStyle = ({ index, disableRotation, cards, isPlayer }) => {
    const { isMobile } = urlParams;
    const { clientWidth, clientHeight } = document.body;
    const isPortrait = isMobile && clientWidth < clientHeight;
    let [cardsLength, lastCardCalc, degPoint, xPosPoint] = [cards?.length, 1, 2, isPortrait ? 35 : isMobile ? 32 : 40];
    const [calcIndexPoint, cardsIsOdd] = [index - cardsLength / 2, cardsLength % 2 === 1];
    const yPosIndex = Math.floor(Math.abs(calcIndexPoint)) - +(calcIndexPoint < 0);
    const cardsYPositionsCalc = [...cards]
        .splice(0, cardsLength / 2)
        ?.map((elem, ind) => (lastCardCalc = ind + lastCardCalc));

    const [calcIndexInOdd, yPos] = [
        cardsIsOdd ? Math.ceil(calcIndexPoint) : calcIndexPoint,
        cardsYPositionsCalc[yPosIndex] || 0
    ];

    let [translateX, rotate] = [xPosPoint * calcIndexInOdd, degPoint * calcIndexInOdd];

    if (!cardsIsOdd) {
        translateX += xPosPoint / 2;
        rotate += degPoint / 2;
    }

    const rotationVal = isPlayer ? rotate : -rotate;
    const rotationPoint = disableRotation ? 0 : rotationVal;

    return {
        transform: `translate(${translateX}%, ${isPlayer ? Math.abs(yPos) : -yPos}%) rotate(${rotationPoint}deg)`
        // zIndex: index + 1
    };
};

export const getSuitByTrumpId = (trump, id) => trump && suits[cardSuits[trump]](id);

const separateHost = () => {
    let host = window.location.host.split(".");
    if (host.length > 2) {
        host.shift();
    }
    host = host.join(".");
    return host;
};

export const handleIsDynamicUrl = urlData => {
    const key = Object.keys(urlData)[0];
    let url = urlData[key];

    if (process.env[`${key}_IS_DYNAMIC`] === "true") {
        const host = separateHost();
        url = `${url}${host}`;
    }

    return url;
};

export const existsFile = url => {
    const http = new XMLHttpRequest();
    http.open("HEAD", url, false);
    http.send();
    return http.status != 404;
};

export const callWithTimeoutRejection = (apiCall, timeout = 3000) => {
    const timeoutPromise = new Promise((resolve, reject) =>
        setTimeout(() => reject(new Error("Timeout to receive response has expired")), timeout)
    );
    const resultPromise = apiCall();

    //for testing
    // const resultPromise = new Promise(resolve => setTimeout(() => resolve(apiCall()), 5000));

    return Promise.race([timeoutPromise, resultPromise]);
};

export const changeKeysRecursive = data => {
    let result;

    if (typeof data === "object" && data) {
        result = {};
        for (let key in data) {
            const value = data[key];
            const actualKey = shortKeys[key] || key;
            const isObjArrType = value && typeof value === "object";
            if (isObjArrType) {
                result[actualKey] = Array.isArray(value) ? value.map(changeKeysRecursive) : changeKeysRecursive(value);
            } else {
                result[actualKey] = value;
            }
        }
    } else {
        result = data;
    }

    return result;
};
