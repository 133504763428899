import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    resources: {
        Accept: "ACCEPT",
        AddGame: "Add Game",
        Audio: "Audio",
        AudioSettings: "Audio Settings",
        Avatar: "Avatar",
        Back: "Back",
        BackgroundMusic: "Background Music",
        BaseStake: "Base Stake",
        Cards: "Cards",
        Carpet: "Carpet",
        ChooseATrump: "CHOOSE A TRUMP",
        Classic: "Classic",
        Confirm: "CONFIRM",
        CurrentStake: "Current Stake",
        Double: "DOUBLE",
        DoubleYourStrike: "DOUBLE YOUR STAKE",
        English: "English",
        Female: "Female",
        FinalStakeDoubling: "Your final stake after doubling:",
        Floor: "Floor",
        Front: "Front",
        GameID: "Game ID",
        Jazz: "Jazz",
        Leave: "LEAVE",
        LeaveTheGame: "LEAVE THE GAME",
        LeaveTheGameConfirmationText: "If You leave the game, You will lose. Are You sure You want to leave the game?",
        Male: "Male",
        MaxStake: "Max Stake",
        Off: "Off",
        Player1: "Player 1",
        Player2: "Player 2",
        Premium: "Premium",
        PressRefresh: "PRESS THE REFRESH BUTTON TO CONTINUE THE GAME",
        Prize: "Prize",
        Redouble: "REDOUBLE",
        Refresh: "REFRESH",
        Rock: "Rock",
        Round: "Round",
        Save: "SAVE",
        Score: "Score",
        Select: "SELECT",
        Selected: "SELECTED",
        Settings: "SETTINGS",
        SoundEffects: "Sound Effects",
        Square: "Square",
        StakeDoubling: "Would You like to double the stake?",
        StakeDoublingOffer: "Your opponent offers to double the stake.Otherwise you will lose",
        Stay: "STAY",
        Surrender: "SURRENDER",
        SurrenderLowCase: "Surrender",
        Table: "Table",
        TimeOut: "Time Out",
        ToEnd: "To End",
        Tour: "Tour",
        TourOrder: "Round",
        Tournament: "Tournament",
        TournamentId: "Tournament ID",
        WeakConnection: "YOUR CONNECTION IS TOO WEAK",
        Wood: "Wood",
        You: "You",
        Me: "Me",
        Info: "info",
        Conventions: "Conventions",
        Incognito: "Incognito",
        Private: "Private",
        HiddenCards: "Hidden Cards",
        WithBlindCard: "With Blind Card",
        Attention: "Attention!",
        YourOpponents: "Your Opponent’s",
        ConnectionLost: "Connection lost",
        StayTuned: "Stay Tuned!",
        GameAutomaticallyResumeConnectionRestored: "the game will automatically resume when the connection is restored",
        Themes: "Themes",
        Animation: "Animation",
        Cancel: "Cancel",
        ChoosingTrump: "Choosing Trump...",
        PleaseWaitUntilOpponentJoin: "Please wait until the opponent will join the game, otherwise you will lose.",
        PleaseWaitUntilPlayersJoin: "Please wait until the players will join the game."
    }
};

export const translationSlice = createSlice({
    name: "translationState",
    initialState,
    reducers: {
        saveTranslationLoading: (state, action) => {
            state.loading = action.payload;
        },
        saveTranslation: (state, action) => {
            state.resources = { ...state.resources, ...action.payload };
        }
    }
});

export const { saveTranslation, saveTranslationLoading } = translationSlice.actions;
export default translationSlice.reducer;
