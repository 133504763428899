import AppConstants from "../constants";
import { addPendingChooseTrumpStack } from "../store/middleware/animationMiddleware";
import { saveChooseTrump } from "../store/reducers/chooseTrumpSlice";
import { selectActiveDealingStep } from "../store/reducers/dealingStepsSlice";
import { saveGameDataTrump } from "../store/reducers/gameDataSlice";
import { savePopupType } from "../store/reducers/popupTypesSlice";

const {
    popupTypes: { CHOOSE_TRUMP }
} = AppConstants;

const usersCardDealingAfterChooseTrump = (pCardsRes, oCardsRes) => (dispatch, getState) => {
    const {
        gameDataState: {
            player: { ...playerData },
            opponent: { ...opponentData }
        }
    } = getState();

    let playerCards = playerData?.cards ? [...playerData.cards] : [];
    let opponentCards = opponentData?.cards ? [...opponentData.cards] : [];

    const cardsWelding = (cards, cardsStock) => {
        const median = Math.round(cards.length / 2) + 1;
        const firstPart = cardsStock.slice(0, median);
        const secondPart = cardsStock.slice(median, cardsStock.length);

        return [...firstPart, ...cards, ...secondPart];
    };

    if (playerCards.length) {
        playerCards = cardsWelding(playerCards, pCardsRes);
        opponentCards = [...opponentCards, ...oCardsRes];
    } else if (opponentCards.length) {
        playerCards = [...playerCards, ...pCardsRes];
        opponentCards = cardsWelding(opponentCards, oCardsRes);
    }

    return {
        playerCards,
        opponentCards: opponentCards.filter(card => card === 0)
    };
};

const trumpChoosedController = res => (dispatch, getState) => {
    const { player: playerRes, opponent: opponentRes, trump } = res;
    const state = getState();

    const {
        gameDataState,
        settingsState: { isAnimationEnabled },
        initialState: {
            gameInitialState: { isGuest }
        },
        gameDataState: {
            isRoundEnded,
            boardCardsWithAnimation,
            player: { ...playerData },
            opponent: { ...opponentData }
        }
    } = state;

    const activeDealingStep = selectActiveDealingStep(state);

    if ((boardCardsWithAnimation.length || activeDealingStep || isRoundEnded) && !isGuest) {
        addPendingChooseTrumpStack(() => dispatch(trumpChoosedController(res)));
        return;
    }

    //detect if has active step before choose trump
    const { playerCards, opponentCards } =
        activeDealingStep && isAnimationEnabled && !isGuest
            ? { playerCards: playerRes.cards, opponentCards: opponentRes.cards }
            : dispatch(usersCardDealingAfterChooseTrump(playerRes.cards, opponentRes.cards));

    dispatch(
        saveGameDataTrump({
            ...gameDataState,
            ...res,
            isGameStarted: true,
            player: {
                ...playerData,
                ...playerRes,
                cards: playerCards
            },
            opponent: {
                ...opponentData,
                ...opponentRes,
                cards: opponentCards
            }
        })
    );
    dispatch(saveChooseTrump(trump));
};

export const handleShowChoseTrumpPopup = () => (dispatch, getState) => {
    const {
        gameDataState: { isPlayerBaron },
        chooseTrumpState: { trump },
        initialState: {
            gameInitialState: { isGuest }
        }
    } = getState();

    !isGuest && !trump && isPlayerBaron && dispatch(savePopupType(CHOOSE_TRUMP));
};

export default trumpChoosedController;
