import { configureStore } from "@reduxjs/toolkit";
import animationMiddleware from "./middleware/animationMiddleware";

import historyState from "./reducers/historySlice";
import lastBeatState from "./reducers/lastBeatSlice";
import settingsState from "./reducers/settingsSlice";
import gameDataState from "./reducers/gameDataSlice";
import initialState from "./reducers/initialStateSlice";
import connectionState from "./reducers/connectionSlice";
import popupTypesState from "./reducers/popupTypesSlice";
import usersTimesState from "./reducers/usersTimesSlice";
import chooseTrumpState from "./reducers/chooseTrumpSlice";
import translationState from "./reducers/translationSlice";
import dataReceivedState from "./reducers/dataReceivedSlice";
import dealingStepsState from "./reducers/dealingStepsSlice";
import lowConnectionState from "./reducers/lowConnectionSlice";
import newRoundPendingState from "./reducers/newRoundPendingSlice";
import dealingAnimationCountState from "./reducers/dealingAnimationCountSlice";
import demoGameState from "./reducers/demoGameSlice";

export const store = configureStore({
    reducer: {
        historyState,
        initialState,
        settingsState,
        gameDataState,
        lastBeatState,
        demoGameState,
        usersTimesState,
        connectionState,
        popupTypesState,
        chooseTrumpState,
        translationState,
        dealingStepsState,
        dataReceivedState,
        lowConnectionState,
        newRoundPendingState,
        dealingAnimationCountState
    },
    devTools: process.env.NODE_ENV === "development",
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(animationMiddleware)
});
