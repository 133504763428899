import { batch } from "react-redux";
import { savePendingNewRound } from "../reducers/newRoundPendingSlice";
import { setDataBeforeNewRound } from "../../controllers/newRoundController";
import { handleShowChoseTrumpPopup } from "../../controllers/trumpChoosedController";
import { urlParams } from "../../helpers";
import { soundPlayByName } from "../../services/sounds";
import {
    resetDealingStepsState,
    saveDealingSteps,
    savePendingDealing,
    selectActiveDealingStep
} from "../reducers/dealingStepsSlice";
import { saveGameUsersData } from "../reducers/gameDataSlice";
import { resetLastBeatState } from "../reducers/lastBeatSlice";
import { saveLastBeatHandle } from "../../controllers/stepDataController";
import { resetPopupType } from "../reducers/popupTypesSlice";
import { saveUsersTimes } from "../reducers/usersTimesSlice";
import { HIDDEN, VISIBILITY_CHANGE } from "../../constants";

const handleCreatePendingStack = () => {
    let stack = [];

    return [
        //add
        value => (stack = [...stack, value]),
        // clear
        () => (stack = []),
        // call
        callback => {
            if (stack?.length) {
                for (let i = 0; i <= stack.length; i++) {
                    stack[i] && typeof stack[i] === "function" && stack[i]();
                }
            }
            stack = [];
            typeof callback === "function" && callback();
        },
        // call first
        () => {
            const firstCall = stack[0];
            typeof firstCall === "function" && firstCall();
            stack.shift();
        },
        // call last
        () => {
            const lastCall = stack[stack.length - 1];
            typeof lastCall === "function" && lastCall();
            stack.pop();
        }
    ];
};

const [addPendingNewRoundStack, clearPendingNewRoundStack, callPendingNewRoundStack] = handleCreatePendingStack();
const [addPendingGameResultStack, clearPendingGameResultStack, callPendingGameResultStack] = handleCreatePendingStack();
const [
    addDealingStepPendingStack,
    clearDealingStepPendingStack,
    callPendingDealingStepStack
] = handleCreatePendingStack();
const [addPendingStepStack, clearPendingStepStack, callPendingStepStack] = handleCreatePendingStack();

export const [
    addPendingChooseTrumpStack,
    clearPendingChooseTrumpStack,
    callPendingChooseTrumpStack
] = handleCreatePendingStack();

export const [
    addPendingStepControllerStack,
    clearPendingStepControllerStack,
    callPendingStepControllerStack,
    callPendingStepControllerStackFirst
] = handleCreatePendingStack();

export const handleClearAllPendingStacks = () => {
    clearPendingStepStack();
    clearPendingNewRoundStack();
    clearPendingGameResultStack();
    clearPendingChooseTrumpStack();
    clearDealingStepPendingStack();
    clearPendingStepControllerStack();
};

const handleClear = () => !document[HIDDEN] && handleClearAllPendingStacks();

document.addEventListener(VISIBILITY_CHANGE, handleClear);
window.addEventListener("offline", handleClear);

const animationMiddleware = ({ getState, dispatch }) => next => action => {
    const { isHistory } = urlParams;

    if (isHistory) {
        return next(action);
    }

    const state = getState();

    const {
        initialState: {
            gameInitialState: { isGuest }
        },
        settingsState: { isAnimationEnabled },
        newRoundPendingState: { pendingNewRoundDealingEnd },
        dealingAnimationCountState: { dealingAnimationCount },
        lastBeatState: { animationsCount: lastBeatAnimations },
        gameDataState: { isRoundEnded, boardCards, boardCardsWithAnimation, player }
    } = state;

    const activeDealingStep = selectActiveDealingStep(state);

    const callNewRoundAfterTimeOut = currentAction => {
        isAnimationEnabled && dispatch(savePendingNewRound(true));
        //set new round data after time out
        setTimeout(() => {
            batch(() => {
                dispatch(setDataBeforeNewRound());
                next(currentAction);
                if (!isAnimationEnabled) {
                    dispatch(handleShowChoseTrumpPopup());
                    callPendingChooseTrumpStack();
                }
            });
        }, 2000);
    };

    const dealingStep1EndCallback = dealingStep => {
        dispatch(saveDealingSteps({ [dealingStep]: false }));
        dispatch(
            saveGameUsersData({
                player: { cards: [] },
                opponent: { cards: [] }
            })
        );
        dispatch(saveDealingSteps({ step2: true }));
    };

    const dealingStep2EndCallback = dealingStep => {
        setTimeout(() => {
            dispatch(saveDealingSteps({ [dealingStep]: false }));
            dispatch(handleShowChoseTrumpPopup());
            callPendingChooseTrumpStack();
        }, 500);
    };

    const dealingStep3EndCallback = dealingStep => {
        dispatch(saveDealingSteps({ [dealingStep]: false, step4: true }));
    };

    const dealingStep4EndCallback = dealingStep => {
        dispatch(
            saveGameUsersData({
                player: { cards: [...player.cards].sort((a, b) => a - b) }
            })
        );

        dispatch(saveDealingSteps({ [dealingStep]: false, step5: true }));
    };

    const dealingStep5EndCallback = dealingStep => {
        dispatch(saveDealingSteps({ [dealingStep]: false }));
        dispatch(savePendingNewRound(false));
        dispatch(savePendingDealing(false));

        callPendingStepStack();
        callPendingStepControllerStackFirst();
        dispatch(
            saveGameUsersData({
                player: { cards: [...player.cards].sort((a, b) => a - b) }
            })
        );
    };

    switch (action.type) {
        case "gameData/saveGameData": {
            const nextActionCallback = () => next(action);
            const { boardCards: actionBoardCards } = action.payload;
            if (!isAnimationEnabled && actionBoardCards.length === 2) {
                setTimeout(() => dispatch(saveLastBeatHandle()), 600);
            }

            if ((activeDealingStep || boardCardsWithAnimation?.length) && isAnimationEnabled) {
                //add step action to pending step stack, when dealing or step animation
                addPendingStepStack(nextActionCallback);
            } else {
                nextActionCallback();
            }
            break;
        }

        case "gameData/saveGameResultData": {
            const nextActionCallback = () => {
                const { player: actionPlayer, opponent } = action?.payload || {};

                next(action);
                handleClearAllPendingStacks();
                dispatch(
                    saveUsersTimes({
                        player: { stepTime: actionPlayer?.stepTime, gameTime: actionPlayer?.gameTime },
                        opponent: {
                            stepTime: opponent?.stepTime,
                            gameTime: opponent?.gameTime
                        }
                    })
                );
            };

            if (
                (pendingNewRoundDealingEnd || boardCardsWithAnimation?.length || lastBeatAnimations) &&
                isAnimationEnabled
            ) {
                //add pending stack if has any animation
                addPendingGameResultStack(nextActionCallback);
            } else {
                nextActionCallback();
            }
            break;
        }

        case "chooseTrumpState/saveChooseTrump": {
            if (boardCardsWithAnimation?.length && isAnimationEnabled && !isGuest) {
                addPendingStepStack(() => next(action));
            } else {
                next(action);
                action.payload && !isAnimationEnabled && dispatch(resetDealingStepsState());
            }
            break;
        }

        case "gameData/saveGameDataTrump": {
            batch(() => {
                const dealingStepsRes = { step1: false, step2: false, step3: true };
                if (!isAnimationEnabled || isGuest) {
                    dealingStepsRes.step3 = false;
                    dispatch(savePendingNewRound(false));
                }

                dispatch(resetPopupType());
                dispatch(saveDealingSteps(dealingStepsRes));
            });

            next(action);
            break;
        }

        case "gameData/saveBoardCardsWithAnimation": {
            const isCardMovedToBoard = action.payload?.length;

            const nextActionCallback = () => {
                isCardMovedToBoard && soundPlayByName("moveCard");
                next(action);
            };

            if (activeDealingStep && isAnimationEnabled) {
                addPendingStepStack(nextActionCallback);
            } else {
                nextActionCallback();
            }

            if (boardCardsWithAnimation?.length > isCardMovedToBoard) {
                if (boardCards.length === 1) {
                    dispatch(resetLastBeatState());
                } else {
                    callPendingGameResultStack(); // call when one not going to last beat
                }
                callPendingStepStack();
                !boardCards.length && callPendingStepControllerStackFirst();
            }

            break;
        }

        case "gameData/saveNewRound": {
            if (isAnimationEnabled && !isGuest) {
                // add new round action to pending new round stack
                const newRoundCallback = () => callNewRoundAfterTimeOut(action);
                addPendingNewRoundStack(newRoundCallback);
            } else {
                callNewRoundAfterTimeOut(action);
            }
            break;
        }

        case "dealingStepsState/saveDealingSteps": {
            const actionSteps = Object.keys(action.payload);
            const activeStep = actionSteps.find(stepName => action.payload[stepName]);

            if (isAnimationEnabled) {
                switch (activeStep) {
                    case "step1":
                        setTimeout(() => soundPlayByName("cardRotate"), 900);
                        setTimeout(() => soundPlayByName("cardRotate"), 1200);
                        break;
                    case "step2":
                        soundPlayByName("fiveCards");
                        break;
                    case "step3":
                        soundPlayByName("thirteenCards");
                        setTimeout(() => soundPlayByName("eightCards"), 1800);
                        break;
                    default:
                        break;
                }
            }

            if (activeStep && isAnimationEnabled && !isGuest) {
                dispatch(savePendingDealing(true));
            }
            next(action);
            break;
        }

        case "dealingAnimationCount/decrementDealingAnimationsCount": {
            if (dealingAnimationCount === 1) {
                if (activeDealingStep) {
                    batch(() => {
                        if (activeDealingStep === "step1") {
                            dealingStep1EndCallback(activeDealingStep);
                        }
                        if (activeDealingStep === "step2") {
                            dealingStep2EndCallback(activeDealingStep);
                        }

                        if (activeDealingStep === "step3") {
                            dealingStep3EndCallback(activeDealingStep);
                        }

                        if (activeDealingStep === "step4") {
                            dealingStep4EndCallback(activeDealingStep);
                        }

                        if (activeDealingStep === "step5") {
                            dealingStep5EndCallback(activeDealingStep);
                        }

                        callPendingDealingStepStack(); //next pending dealing step
                    });
                }
                callPendingGameResultStack();
            }
            next(action);
            break;
        }

        case "gameData/saveDealingStepData": {
            if (activeDealingStep && isAnimationEnabled && !isGuest) {
                addDealingStepPendingStack(() => {
                    next(action);
                });
            } else {
                next(action);
            }
            break;
        }

        case "lastBeatState/lastBeatDecrementAnimationsCount": {
            if (lastBeatAnimations === 1) {
                dispatch(saveLastBeatHandle());
                callPendingGameResultStack();
                setTimeout(callPendingStepControllerStackFirst, 500);
                if (isRoundEnded) {
                    callPendingNewRoundStack();
                }
            }
        }

        /* falls through */

        default:
            next(action);
    }
};

export default animationMiddleware;
