import React from "react";

const ArrowToLeftIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.62996 12L8 10.59L3.5498 6L8 1.41L6.62996 1.19773e-07L0.800001 6L6.62996 12Z" />
        </svg>
    );
};

export default ArrowToLeftIcon;
