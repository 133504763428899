import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pendingNewRoundDealingEnd: false
};

export const newRoundPendingSlice = createSlice({
    name: "newRoundPendingState",
    initialState,
    reducers: {
        savePendingNewRound: (state, action) => {
            state.pendingNewRoundDealingEnd = action.payload;
        }
    }
});

export const { savePendingNewRound } = newRoundPendingSlice.actions;
export default newRoundPendingSlice.reducer;
