import { detect } from "detect-browser";
const browser = detect();

const AppConstants = {
    network: {
        CURRENT_GAME_NAME: "hokm",
        REACT_APP_URL: process.env.REACT_APP_URL,
        REACT_APP_IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
        REACT_APP_TRANSLATIONS_URL: process.env.REACT_APP_TRANSLATIONS_URL,
        network_request_url: {
            API: "api",
            DEMO: "Demo",
            GAME: "Game",
            RULES: "rules",
            HISTORY: "History",
            GET_RULE: "getrule",
            SETTINGS: "Settings",
            GET_HISTORY: "GetHistory",
            GET_SETTINGS: "GetSettings",
            CHANGE_SETTINGS: "ChangeSettings",
            GET_TRANSLATIONS: "GetTranslations"
        },
        socket: {
            GET_INITIAL_STATE: "GetInitialState",
            INITIAL_STATE: "InitialState",
            GET_GAME_DATA: "getGameData",
            GAME_DATA: "GameData",
            HIT_ME: "HitMe",
            STEP: "Step",
            STAND: "Stand",
            BLIND_CARD: "BlindCard",
            DOUBLE_STAKE: "DoubleStake",
            DOUBLE_STAKE_REQUEST: "DoubleStakeRequest",
            ROUND_RESULT: "RoundResult",
            CONNECTION_CHANGED: "ConnectionChanged",
            NEW_ROUND: "NewRound",
            LEAVE_GAME: "LeaveGame",
            DOUBLE_STAKE_RESPONSE: "DoubleStakeResponse",
            CHOOSE_TRUMP: "chooseTrump",
            TRUMP_CHOOSED: "TrumpChoosed",
            MOVE: "Move",
            GAME_RESULT: "GameResult",
            GET_INET_SPEED: "GetInetSpeed",
            INET_SPEED: "InetSpeed",
            SWITCH_BOT_MODE: "SwitchBotMode",
            BOT_MODE_SWITCHED: "BotModeSwitched"
        },
        socketQueries: {
            doubleStakeResponse: {
                double: 1,
                accept: 2,
                redouble: 3,
                surrender: 4
            }
        }
    },
    dimensions: {
        DEFAULT_ASPECT_RATIO_WIDTH_DESKTOP: 16,
        DEFAULT_ASPECT_RATIO_HEIGHT_DESKTOP: 9,
        DEFAULT_ASPECT_RATIO_WIDTH_MOBILE: 19.5,
        DEFAULT_ASPECT_RATIO_HEIGHT_MOBILE: 9,
        FONT_SIZE_SCALE_INDEX_DESKTOP: 1280,
        FONT_SIZE_SCALE_INDEX_LANDSCAPE: 812,
        FONT_SIZE_SCALE_INDEX_PORTRAIT: 375
    },
    devicesMode: {
        DESKTOP: "desktop",
        MOBILE: "mobile"
    },
    popupTypes: {
        SETTINGS: "settings",
        DOUBLE_STAKE_CONFIRMATION: "doubleStakeConfirmation",
        DOUBLE_STAKE: "doubleStake",
        LEAVE_GAME: "leaveGame",
        GAME_OVER: "gameOver",
        REVANCHE_OFFER: "revancheOffer",
        LOST_CONNECTION: "lostConnection",
        CHOOSE_TRUMP: "chooseTrump"
    },
    cardAnimationConfig: {
        STAND_ANIMATION_COUNT_PER_CARD: 2,
        FLIPPED_ANIMATION_COUNT_PER_CARD: 1
    },
    cardSuits: {
        1: "Clubs",
        2: "Hearts",
        3: "Spades",
        4: "Diamonds"
    },
    themeCardTypes: {
        1: "c",
        2: "d",
        3: "e"
    },
    shortKeys: {
        p: "player",
        ip: "isPlayer",
        ic: "isConnected",
        gc: "gameConfiguration",
        pis: "playerInitialState",
        ois: "opponentInitialState",
        ti: "tournamentInfo",
        ig: "isGuest",
        nn: "playerNickName",
        gp: "gamePoints",
        gt: "gameTime",
        st: "stepTime",
        msd: "maxStakeDoubling",
        in: "incognito",
        id: "isDemo",
        ipb: "isPlayerBaron",
        t: "trump",
        pt: "playerTrumpCard",
        ot: "opponentTrumpCard",
        gs: "gameScore",
        dsd: "doubleStakeData",
        ps: "popups",
        bc: "boardCards",
        lb: "lastBeat",
        igs: "isGameStarted",
        is: "isSwitched",
        n: "notification",
        c: "cards",
        vm: "validMoves",
        os: "opponentGameScore",
        rs: "roundsScores",
        or: "opponentRoundScore",
        b: "isPlayerBaron",
        s: "score",
        cs: "currentStake",
        mds: "isMaxDoubleStake",
        ctp: "chooseTrumpPopup",
        ns: "nextStake",
        cr: "canRedouble",
        pb: "isPlayerBeat",
        ipw: "isPlayerWin",
        m: "move",
        rr: "roundResult",
        nr: "newRound",
        ci: "cardId",
        prr: "playerRoundResultData",
        orr: "opponentRoundResultData",
        f: "finishStatus",
        o: "opponent",
        ipt: "isPlayerTurn",
        ipp: "isPlayerPlayed",
        ds: "doubleStake",
        pp: "popups",
        w: "point",
        pe: "private",
        pg: "playerGameScore",
        og: "opponentGameScore",
        pr: "playerRoundScore",
        ss: "status",
        Ipb: "isPlayerBaron",
        oc: "opponentTrumpCard",
        pc: "playerTrumpCard",
        td: "tournamentId",
        to: "tourOrder",
        pf: "prizeFund"
    }
};

export default AppConstants;
export const BROWSER_NAME = browser.name;
export const IS_IOS = (() => /iPad|iPhone|iPod/.test(navigator.userAgent))();
export const IS_IOS_CHROME = IS_IOS && (BROWSER_NAME === "chrome" || BROWSER_NAME === "crios");

export const [HIDDEN, VISIBILITY_CHANGE] = (() => {
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    return [hidden, visibilityChange];
})();

export const VALIDATION_COOKIE_NAME = "_ga_anl_cf3q";
