const PrevStepIcon = () => (
    <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.9994 4.66667V0.946667C10.9994 0.346667 10.2794 0.0533333 9.86605 0.48L4.79939 5.53333C4.53272 5.8 4.53272 6.21333 4.79939 6.48L9.85272 11.5333C10.2794 11.9467 10.9994 11.6533 10.9994 11.0533V7.33333C15.9727 7.33333 19.9061 11.8933 18.8127 17.0533C18.1861 20.08 15.7327 22.52 12.7194 23.1467C7.95939 24.1467 3.71939 20.88 3.07939 16.4667C2.98605 15.8267 2.42605 15.3333 1.77272 15.3333C0.97272 15.3333 0.33272 16.04 0.439386 16.84C1.26605 22.6933 6.83939 27.0267 13.1461 25.8C17.3061 24.9867 20.6527 21.64 21.4661 17.48C22.7861 10.64 17.5861 4.66667 10.9994 4.66667Z"
            fill="white"
        />
    </svg>
);

export default PrevStepIcon;
