import React from "react";
import PropTypes from "prop-types";

const Hearts = id => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.2362 0C8.90533 0 7.73249 0.685092 7.04167 1.72626C6.35084 0.685092 5.17801 0 3.84719 0C1.72219 0 0 1.74688 0 3.90137C0 7.91743 6.10645 13 7.04167 13C7.97689 13 14.0833 7.91743 14.0833 3.90137C14.0833 1.74688 12.3611 0 10.2362 0Z"
                fill={`url(#paint0_linear_734_207344_${id || ""})`}
            />
            <defs>
                <linearGradient
                    id={`paint0_linear_734_207344_${id || ""}`}
                    x1="1.58596"
                    y1="1.38667"
                    x2="9.01324"
                    y2="12.2599"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AD2A00" />
                    <stop offset="1" stopColor="#7B0059" />
                </linearGradient>
            </defs>
        </svg>
    );
};

Hearts.defaultProps = {
    id: ""
};

Hearts.propTypes = {
    id: PropTypes.string
};

export default Hearts;
