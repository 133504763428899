import React from "react";
import PropTypes from "prop-types";

const Spades = id => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.7024 11.4615C20.6541 8.97228 11.9344 0 11.9344 0C11.9344 0 3.19385 8.95644 1.16377 11.4615C0.536521 12.3458 0.147276 13.3803 0.0343031 14.4632C-0.0786703 15.546 0.0885151 16.6401 0.519429 17.6377C0.950343 18.6353 1.6302 19.5021 2.49206 20.153C3.35392 20.8038 4.3682 21.2163 5.4351 21.3498C6.50158 21.3573 7.55767 21.1369 8.53434 20.703C9.51102 20.2691 10.3863 19.6314 11.1031 18.8315L9.80012 24H14.1887L12.8857 18.8104C13.5768 19.6078 14.4198 20.2555 15.3643 20.7145C16.3087 21.1736 17.3351 21.4346 18.3816 21.4818C19.4832 21.3866 20.5392 20.992 21.4377 20.3394C22.3363 19.6869 23.0442 18.8007 23.4867 17.7744C23.9293 16.7481 24.09 15.6198 23.952 14.5087C23.8139 13.3975 23.3822 12.3447 22.7024 11.4615Z"
                fill={`url(#paint0_linear_1061_68844_${id || ""})`}
            />
            <defs>
                <linearGradient
                    id={`paint0_linear_1061_68844_${id || ""}`}
                    x1="6.69781"
                    y1="2.32007"
                    x2="17.3023"
                    y2="16.9158"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0438688" stopColor="#0F4578" />
                    <stop offset="1" stopColor="#020415" />
                </linearGradient>
            </defs>
        </svg>
    );
};

Spades.defaultProps = {
    id: ""
};

Spades.propTypes = {
    id: PropTypes.string
};

export default Spades;
