import {
    JsonHubProtocol,
    HubConnectionBuilder,
    HttpTransportType,
    LogLevel,
    HubConnectionState
} from "@microsoft/signalr";
import { batch } from "react-redux";
import AppConstants from "../constants";
import { clearLowConnectionTimeOuts } from "../controllers/lowConnectionController";
import { handleIsDynamicUrl } from "../helpers";
import { resetPopupType, savePopupType } from "../store/reducers/popupTypesSlice";
import { store } from "../store/store";

const {
    network: { REACT_APP_URL },
    popupTypes: { LOST_CONNECTION }
} = AppConstants;

const startSignalRConnection = async connection => {
    const { dispatch } = store;

    try {
        await connection.start();
        dispatch(resetPopupType());
        console.log("SignalR connection established");
    } catch (err) {
        const connectionState = connection?.connectionState || connection?._connectionState;

        if (
            connectionState === HubConnectionState.Disconnected ||
            connectionState === HubConnectionState.Disconnecting
        ) {
            clearLowConnectionTimeOuts();
            console.error("SignalR Connection Error: ", err);
            batch(() => {
                dispatch(savePopupType(LOST_CONNECTION));
            });
            setTimeout(() => startSignalRConnection(connection), 1000);
        }
    }
};

export const [setConnection, getConnection, closeConnection] = (() => {
    let connection;

    const connectionOptions = {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
    };

    const close = () => connection?.stop();

    const setup = options => {
        if (connection) {
            close();
        }

        const { gameId, token } = options;
        const connectionHub = `${handleIsDynamicUrl({ REACT_APP_URL })}/game?gameId=${gameId}&token=${token}`;

        connection = new HubConnectionBuilder()
            .withUrl(connectionHub, connectionOptions)
            .withAutomaticReconnect([0, 0, 1000])
            .withHubProtocol(new JsonHubProtocol())
            .configureLogging(LogLevel.Information)
            .build();

        connection?.onclose(error => {
            console.log("Connection closed due to error. Try refreshing this page to restart the connection", error);
            startSignalRConnection(connection);
        });

        connection?.onreconnecting(error => {
            console.log("Connection lost due to error. Reconnecting.", error);
        });

        connection?.onreconnected(connectionId => {
            console.log("Connection reestablished. Connected with connectionId", connectionId);
        });

        return startSignalRConnection(connection);
    };

    return [setup, () => connection, close];
})();
