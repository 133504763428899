import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StartDemo from "./components/startDemo/StartDemo";
import { urlParams } from "./core/helpers";
import { handleStart } from "./core/controllers/demoGameController";

const App = React.lazy(() => import("./App"));
const { isDemo, lang } = urlParams;

document.documentElement.lang = lang || "en";

const AppRoot = () => {
    const [showDemoButton, setShowDemoButton] = useState(isDemo);
    const isGameEnded = useSelector(state => state.gameDataState?.finishStatus);
    const gameConfigIsDemo = useSelector(state => state.initialState.gameInitialState?.gameConfiguration?.isDemo);

    useEffect(() => {
        if (gameConfigIsDemo && isGameEnded) {
            setTimeout(() => setShowDemoButton(true), 3000);
        }
    }, [gameConfigIsDemo, isGameEnded]);

    return <Suspense fallback={null}>{showDemoButton ? <StartDemo onClick={handleStart} /> : <App />}</Suspense>;
};

export default AppRoot;
