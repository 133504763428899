import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cards: null,
    isPlayerBeat: undefined,
    animationsCount: 0
};

export const lastBeatSlice = createSlice({
    name: "lastBeatState",
    initialState,
    reducers: {
        saveLastBeat: (state, action) => ({
            ...action.payload,
            animationsCount: state.animationsCount
        }),
        lastBeatDecrementAnimationsCount: state => {
            +state.animationsCount--;
        },
        lastBeatIncrementAnimationsCount: state => {
            +state.animationsCount++;
        },
        setLastBeatAnimationsCountByAmount: (state, action) => {
            state.animationsCount = action.payload;
        },
        resetLastBeatState: () => ({
            ...initialState
        })
    }
});

export const {
    saveLastBeat,
    resetLastBeatState,
    lastBeatDecrementAnimationsCount,
    lastBeatIncrementAnimationsCount,
    setLastBeatAnimationsCountByAmount
} = lastBeatSlice.actions;
export default lastBeatSlice.reducer;
