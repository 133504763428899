import React from "react";

const ArrowToRightIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.8082 0.5L0.666504 1.675L4.37501 5.5L0.666504 9.325L1.8082 10.5L6.6665 5.5L1.8082 0.5Z" />
        </svg>
    );
};

export default ArrowToRightIcon;
