const LeaveGameIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M2 2.50006H9V0.500061H0V18.5001H9V16.5001H2V2.50006Z" />
        </g>
        <g>
            <path d="M18 9.50006L14 5.50006V8.50006H6V10.5001H14V13.5001L18 9.50006Z" />
        </g>
    </svg>
);

export default LeaveGameIcon;
