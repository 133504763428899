import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dealingAnimationCount: 0
};

export const dealingAnimationCountSlice = createSlice({
    name: "dealingAnimationCount",
    initialState: { ...initialState },
    reducers: {
        decrementDealingAnimationsCount: state => {
            state.dealingAnimationCount > 0 ? state.dealingAnimationCount-- : 0;
        },
        incrementDealingAnimationsCount: state => {
            state.dealingAnimationCount++;
        },
        resetDealingAnimationCountState: () => ({
            ...initialState
        })
    }
});

export const {
    decrementDealingAnimationsCount,
    incrementDealingAnimationsCount,
    resetDealingAnimationCountState
} = dealingAnimationCountSlice.actions;
export default dealingAnimationCountSlice.reducer;
