const NextRoundIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.63333 18.15L12.25 11.3667C13.1833 10.7 13.1833 9.3 12.25 8.65L2.63333 1.85C1.51667 1.08333 0 1.86667 0 3.21667V16.7833C0 18.1333 1.51667 18.9167 2.63333 18.15ZM16.6667 1.66667V18.3333C16.6667 19.25 17.4167 20 18.3333 20C19.25 20 20 19.25 20 18.3333V1.66667C20 0.75 19.25 0 18.3333 0C17.4167 0 16.6667 0.75 16.6667 1.66667Z"
            fill="white"
        />
    </svg>
);

export default NextRoundIcon;
