import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    trump: null
};

export const chooseTrumpSlice = createSlice({
    name: "chooseTrumpState",
    initialState,
    reducers: {
        saveChooseTrump: (state, action) => {
            state.trump = action.payload;
        }
    }
});

export const { saveChooseTrump } = chooseTrumpSlice.actions;
export default chooseTrumpSlice.reducer;
