import React, { memo } from "react";
import { useSelector } from "react-redux";
import ImageWithErrorLoad from "../imageWithErrorLoad/ImageWithErrorLoad";
import boardImage1 from "../../assets/images/bg-image1.png";

const GameBackground = () => {
    const backgroundUrl = useSelector(state => state.settingsState?.backgroundUrl);

    return <ImageWithErrorLoad className="bg-image" alt="board" src={backgroundUrl} defaultSrc={boardImage1} />;
};

export default memo(GameBackground);
