import { createSlice, createSelector } from "@reduxjs/toolkit";
import { urlParams } from "../../helpers";

const initialState = {
    player: {},
    opponent: {},
    boardCards: [],
    boardCardsWithAnimation: []
};

export const gameDataSlice = createSlice({
    name: "gameData",
    initialState: { ...initialState },
    reducers: {
        saveGameData: (state, action) => action.payload,
        saveGameUsersData: (state, action) => ({
            ...state,
            player: {
                ...state.player,
                ...action.payload.player
            },
            opponent: {
                ...state.opponent,
                ...action.payload.opponent
            }
        }),
        saveGameResultData: (state, action) => ({
            ...state,
            ...action.payload,
            player: {
                ...state.player,
                ...action.payload.player
            },
            opponent: {
                ...state.opponent,
                ...action.payload.opponent
            }
        }),
        saveGameDataTrump: (state, action) => action.payload,
        saveNewRound: (state, action) => action.payload,
        saveBoardCards: (state, action) => ({
            ...state,
            boardCards: action.payload
        }),
        clearBoardCards: state => ({
            ...state,
            boardCards: []
        }),
        saveBoardCardsWithAnimation: (state, action) => ({
            ...state,
            boardCardsWithAnimation: action.payload
        }),
        saveDealingStepData: (state, action) => ({
            ...state,
            ...action.payload
        }),
        resetGameDateState: state => ({
            isGameStarted: state.isGameStarted,
            ...initialState
        })
    }
});

const { win } = urlParams;
const gameDataSelect = state => state.gameDataState;
const chooseTrumpSelect = state => state.chooseTrumpState;
const settingsSelect = state => state.settingsState;
const dealingStepsSelect = state => state.dealingStepsState;

export const selectPrizeWithDoubleStack = createSelector(
    gameDataSelect,
    gameDataState => (gameDataState.doubleStakeData?.currentStake || 1) * win
);

export const selectIsDealingEnded = createSelector(
    chooseTrumpSelect,
    settingsSelect,
    dealingStepsSelect,
    (chooseTrumpState, settings, dealingSteps) =>
        !!(chooseTrumpState.trump && (!dealingSteps.pendingDealingEnd || !settings.isAnimationEnabled))
);

export const {
    saveNewRound,
    saveGameData,
    saveBoardCards,
    clearBoardCards,
    saveGameDataTrump,
    saveGameUsersData,
    resetGameDateState,
    saveGameResultData,
    saveDealingStepData,
    saveBoardCardsWithAnimation
} = gameDataSlice.actions;
export default gameDataSlice.reducer;
