import React from "react";
import PropTypes from "prop-types";

const Clubs = id => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.35642 3.99416C7.92403 3.91378 7.41571 3.97818 6.9567 4.16C7.19687 3.72057 7.33932 3.21504 7.33932 2.76108C7.33932 1.64979 6.48554 0.748657 5.43265 0.748657C4.37976 0.748657 3.52598 1.64979 3.52598 2.76133C3.52598 3.21529 3.66843 3.72081 3.9086 4.16025C3.44937 3.97842 2.94105 3.91402 2.50865 3.9944C1.47159 4.18736 0.779069 5.23135 0.96189 6.32569C1.14471 7.42027 2.13383 8.15121 3.17089 7.95824C3.92649 7.81758 4.75688 7.17719 5.08146 6.41648C5.14638 8.19696 4.61856 9.74866 4.61856 9.74866L6.24675 9.74866C6.24675 9.74866 5.72099 8.18365 5.78361 6.41551C6.10774 7.17647 6.93858 7.81733 7.69441 7.958C8.73147 8.15096 9.72036 7.42003 9.90341 6.32544C10.086 5.2311 9.39348 4.18736 8.35642 3.99416Z"
                fill={`url(#paint0_linear_1061_87537_${id || ""})`}
            />
            <defs>
                <linearGradient
                    id={`paint0_linear_1061_87537_${id || ""}`}
                    x1="3.4443"
                    y1="1.61868"
                    x2="7.42096"
                    y2="7.0921"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0438688" stopColor="#0F4578" />
                    <stop offset="1" stopColor="#020415" />
                </linearGradient>
            </defs>
        </svg>
    );
};

Clubs.defaultProps = {
    id: ""
};

Clubs.propTypes = {
    id: PropTypes.string
};

export default Clubs;
