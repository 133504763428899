import React from "react";
import GameBackground from "../gameBackground/GameBackground";
import PropTypes from "prop-types";

const StartDemo = ({ onClick }) => {
    return (
        <div className="start-demo-container">
            <button className="start-button" onClick={onClick}>
                START
            </button>
            <GameBackground />
        </div>
    );
};

StartDemo.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default StartDemo;
