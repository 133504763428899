import { Howl } from "howler";

const config = {
    src: ["/Sounds.mp3"],
    preload: true,
    html5: true,
    sprite: {
        cardRotate: [0, 1000],
        fiveCards: [1000, 1000],
        eightCards: [2000, 2000],
        thirteenCards: [4000, 2000],
        moveCard: [6000, 1000]
    },
    pool: 0
};

export const [enableSounds, disableSounds, soundPlayByName] = (() => {
    let sounds = null;

    const disable = () => {
        sounds = null;
    };

    const setup = () => {
        if (!sounds) {
            sounds = new Howl(config);
        }
    };

    return [setup, disable, name => sounds?.play(name)];
})();
