import { batch } from "react-redux";
import { saveChooseTrump } from "../store/reducers/chooseTrumpSlice";
import { saveDealingSteps } from "../store/reducers/dealingStepsSlice";
import { resetGameDateState, saveNewRound } from "../store/reducers/gameDataSlice";
import { resetLastBeatState } from "../store/reducers/lastBeatSlice";

const newRoundController = res => (dispatch, getState) => {
    const { gameDataState } = getState();
    const { player: playerRes, opponent: opponentRes, gameScore: resGameScore } = res;

    const roundsScores = gameDataState.gameScore?.roundsScores || [];
    const actualRoundsScores = [...roundsScores, ...resGameScore.roundsScores];

    batch(() => {
        dispatch(
            saveNewRound({
                ...gameDataState,
                ...res,
                boardCards: [],
                boardCardsWithAnimation: [],
                isRoundEnded: false,
                isGameStarted: false,
                gameScore: {
                    ...res.gameScore,
                    roundsScores: actualRoundsScores
                },
                player: {
                    ...playerRes
                },
                opponent: {
                    ...opponentRes
                }
            })
        );
    });
};

export const setDataBeforeNewRound = () => (dispatch, getState) => {
    const state = getState();

    const {
        initialState: {
            gameInitialState: { isGuest }
        },
        settingsState: { isAnimationEnabled }
    } = state;

    batch(() => {
        dispatch(saveChooseTrump(null));
        dispatch(resetGameDateState());
        dispatch(resetLastBeatState());
        dispatch(saveDealingSteps({ step2: !isGuest && isAnimationEnabled }));
    });
};

export default newRoundController;
