const PlayIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.666 7.62003V24.38C10.666 25.658 12.0735 26.4345 13.1574 25.7389L26.3259 17.3589C27.3289 16.728 27.3289 15.272 26.3259 14.6249L13.1574 6.26111C12.0735 5.56548 10.666 6.342 10.666 7.62003Z"
            fill="white"
        />
    </svg>
);

export default PlayIcon;
