import React from "react";
import PropTypes from "prop-types";

const Diamonds = id => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.53089 4.48468L4.96407 9.17779L0.925942 4.88324L4.49305 0.190117L8.53089 4.48468Z"
                fill={`url(#paint0_linear_1061_87530_${id || ""})`}
            />
            <defs>
                <linearGradient
                    id={`paint0_linear_1061_87530_${id || ""}`}
                    x1="1.59709"
                    y1="1.30321"
                    x2="7.38775"
                    y2="7.26388"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AD2A00" />
                    <stop offset="1" stopColor="#7B0059" />
                </linearGradient>
            </defs>
        </svg>
    );
};

Diamonds.defaultProps = {
    id: ""
};

Diamonds.propTypes = {
    id: PropTypes.string
};

export default Diamonds;
