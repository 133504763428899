const ConnectionLostIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient
                id="paint0_linear_358_247823"
                x1="0.429687"
                y1="0.406738"
                x2="12.4238"
                y2="27.2808"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F3F3F3" />
                <stop offset="1" stopColor="#B7B7B7" />
            </linearGradient>
        </defs>
        <g>
            <path
                d="M23.2868 11.1039L25.5725 8.81817C21.3097 4.55531 15.4354 2.93245 9.9154 3.89245L12.864 6.84103C16.6354 6.81817 20.4183 8.23531 23.2868 11.1039ZM21.0011 13.3896C19.7668 12.1553 18.304 11.2753 16.7497 10.7267L20.2011 14.1782L21.0011 13.3896ZM9.57254 17.961L13.0011 21.3896L16.4297 17.961C14.544 16.0639 11.4697 16.0639 9.57254 17.961ZM3.18397 0.406738L1.57254 2.01817L5.05826 5.50388C3.38969 6.33817 1.82397 7.43531 0.429688 8.81817L2.7154 11.1039C4.12112 9.69817 5.74397 8.63531 7.48112 7.92674L10.0411 10.4867C8.18969 10.9782 6.45254 11.9496 5.00112 13.3896L7.28683 15.6753C8.82969 14.1325 10.8411 13.3439 12.8754 13.321L20.9668 21.4125L22.5783 19.801L3.18397 0.406738Z"
                fill="url(#paint0_linear_358_247823)"
            />
        </g>
    </svg>
);

export default ConnectionLostIcon;
