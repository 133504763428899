const NextStepIcon = () => (
    <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.0006 4.66667V0.946667C11.0006 0.346667 11.7206 0.0533333 12.1339 0.48L17.2006 5.53333C17.4673 5.8 17.4673 6.21333 17.2006 6.48L12.1473 11.5333C11.7206 11.9467 11.0006 11.6533 11.0006 11.0533V7.33333C6.02728 7.33333 2.09395 11.8933 3.18728 17.0533C3.81395 20.08 6.26728 22.52 9.28061 23.1467C14.0406 24.1467 18.2806 20.88 18.9206 16.4667C19.0139 15.8267 19.5739 15.3333 20.2273 15.3333C21.0273 15.3333 21.6673 16.04 21.5606 16.84C20.7339 22.6933 15.1606 27.0267 8.85395 25.8C4.69395 24.9867 1.34728 21.64 0.533947 17.48C-0.786055 10.64 4.41395 4.66667 11.0006 4.66667Z"
            fill="white"
        />
    </svg>
);

export default NextStepIcon;
