import { HIDDEN, VISIBILITY_CHANGE } from "../constants";
import { getInetSpeed } from "../services/signalInvokes";
import { saveIsConnectionLow } from "../store/reducers/lowConnectionSlice";

let timeOut1;
let timeOut2;
let isFirstCall = true;

export const clearLowConnectionTimeOuts = () => {
    clearTimeout(timeOut1);
    clearTimeout(timeOut2);
};

const handleClearTimeOuts = () => document[HIDDEN] && clearLowConnectionTimeOuts();
document.addEventListener(VISIBILITY_CHANGE, handleClearTimeOuts);
window.addEventListener("offline", handleClearTimeOuts);

const lowConnectionController = () => (dispatch, getState) => {
    const {
        gameDataState: { finishStatus }
    } = getState();

    clearLowConnectionTimeOuts();

    if (finishStatus) {
        return;
    }

    if (isFirstCall) {
        isFirstCall = false;
        return getInetSpeed();
    }

    timeOut1 = setTimeout(() => {
        getInetSpeed();
        timeOut2 = setTimeout(() => dispatch(saveIsConnectionLow(true)), 3000);
    }, 10000);
};

export default lowConnectionController;
