import { batch } from "react-redux";
import { move } from "../services/signalInvokes";
import { clearBoardCards, saveGameData } from "../store/reducers/gameDataSlice";
import { saveBoardCardsWithAnimation } from "../../core/store/reducers/gameDataSlice";
import newRoundController from "./newRoundController";
import { saveIsConnectionLow } from "../store/reducers/lowConnectionSlice";
import { saveUsersTimes } from "../store/reducers/usersTimesSlice";
import { saveLastBeat } from "../store/reducers/lastBeatSlice";
import { soundPlayByName } from "../services/sounds";
import { addPendingStepControllerStack } from "../store/middleware/animationMiddleware";

const stepDataController = res => (dispatch, getState) => {
    const {
        gameDataState,
        gameDataState: {
            boardCards,
            isPlayerTurn,
            trump,
            isRoundEnded: roundEnded,
            boardCardsWithAnimation,
            player: { ...playerData },
            opponent: { ...opponentData }
        },
        initialState: {
            gameInitialState: { isGuest }
        },
        settingsState: { isAnimationEnabled }
    } = getState();

    if (boardCardsWithAnimation.length || (roundEnded && isAnimationEnabled) || (!isAnimationEnabled && !trump)) {
        addPendingStepControllerStack(() => dispatch(stepDataController(res)));
        return;
    }

    const {
        newRound,
        lastBeat,
        roundResult,
        isPlayerWin,
        move: resMove,
        player: playerRes,
        opponent: opponentRes,
        boardCards: boardCardsRes,
        isPlayerTurn: isPlayerTurnRes
    } = res;

    const isRoundEnded = typeof isPlayerWin === "boolean";

    //step finally data
    const stepResultData = {
        ...gameDataState,
        ...res,
        isRoundEnded,
        isGameStarted: true,
        player: { ...playerData, ...playerRes },
        opponent: { ...opponentData, ...opponentRes }
    };

    const updateTimers = () =>
        dispatch(
            saveUsersTimes({
                player: { stepTime: playerRes.stepTime, gameTime: playerRes.gameTime },
                opponent: { stepTime: opponentRes.stepTime, gameTime: opponentRes.gameTime }
            })
        );

    if (resMove) {
        const movedCardId = resMove.cardId;
        const boardCardsResult = isRoundEnded
            ? [...boardCards, movedCardId]
            : lastBeat && !boardCardsRes?.length
            ? lastBeat.cards
            : boardCardsRes;

        if (isGuest || !isPlayerTurn) {
            const playerCards = playerData?.cards ? [...playerData.cards] : [];
            const opponentCards = opponentData?.cards ? [...opponentData.cards] : [];

            if (!isPlayerTurn) {
                opponentCards[opponentCards.length - 1] = movedCardId;
            } else if (isPlayerTurn && isGuest) {
                playerCards[playerCards.length - 1] = movedCardId;
            }

            dispatch(
                saveGameData({
                    ...gameDataState,
                    isPlayerTurn: isPlayerTurnRes,
                    player: { ...playerData, cards: [...playerCards] },
                    opponent: { ...opponentData, cards: [...opponentCards] }
                })
            );
        }

        if (isAnimationEnabled) {
            dispatch(saveBoardCardsWithAnimation([...boardCardsWithAnimation, { id: movedCardId }]));
            isPlayerTurnRes ? updateTimers() : setTimeout(updateTimers, 400); // timers daley show cases
        } else {
            soundPlayByName("moveCard");
            updateTimers();
        }

        stepResultData.boardCards = boardCardsResult.length ? boardCardsResult : [];

        if (isRoundEnded) {
            newRound && dispatch(newRoundController(newRound));
            stepResultData.player.cards = roundResult.playerRoundResultData.cards;
            stepResultData.player.notification = null;
            stepResultData.opponent.notification = null;
            stepResultData.opponent.cards = [...roundResult.opponentRoundResultData.cards].sort((a, b) => a - b);
        }

        dispatch(saveGameData(stepResultData));
    }
};

export const sendCardStep = card => async dispatch => {
    try {
        await move(card.id);
    } catch (err) {
        dispatch(saveIsConnectionLow(true));
        console.log(err);
    }
};

export const saveLastBeatHandle = () => (dispatch, getState) => {
    const {
        gameDataState: { boardCards, lastBeat }
    } = getState();

    batch(() => {
        dispatch(
            saveLastBeat({
                cards: boardCards,
                isPlayerBeat: lastBeat?.isPlayerBeat
            })
        );

        dispatch(clearBoardCards());
    });
};

export default stepDataController;
