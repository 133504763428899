import AppConstants from "../constants";
import { getConnection } from "./socket";

const {
    MOVE,
    LEAVE_GAME,
    CHOOSE_TRUMP,
    GET_GAME_DATA,
    GET_INET_SPEED,
    GET_INITIAL_STATE,
    DOUBLE_STAKE_REQUEST,
    DOUBLE_STAKE_RESPONSE,
    SWITCH_BOT_MODE
} = AppConstants.network.socket;

const invoke = (...options) => () => {
    const connection = getConnection();
    const { connectionState, _connectionState } = connection;
    const isConnected = connection && (connectionState === "Connected" || _connectionState === "Connected");
    return isConnected && connection.invoke(...options);
};

const getInitialState = invoke(GET_INITIAL_STATE),
    leaveGame = invoke(LEAVE_GAME),
    getGameData = invoke(GET_GAME_DATA),
    getInetSpeed = invoke(GET_INET_SPEED),
    switchMode = invoke(SWITCH_BOT_MODE),
    doubleStake = invoke(DOUBLE_STAKE_REQUEST),
    move = (...args) => invoke(MOVE, ...args)(),
    chooseTrump = (...args) => invoke(CHOOSE_TRUMP, ...args)(),
    doubleStakeResponse = value => invoke(DOUBLE_STAKE_RESPONSE, value)();

export {
    getInitialState,
    getGameData,
    leaveGame,
    doubleStake,
    doubleStakeResponse,
    chooseTrump,
    move,
    getInetSpeed,
    switchMode
};
