const PauseIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.8571 26.6666C12.4286 26.6666 13.7143 25.3809 13.7143 23.8095V9.52377C13.7143 7.95234 12.4286 6.66663 10.8571 6.66663C9.28571 6.66663 8 7.95234 8 9.52377V23.8095C8 25.3809 9.28571 26.6666 10.8571 26.6666ZM19.4286 9.52377V23.8095C19.4286 25.3809 20.7143 26.6666 22.2857 26.6666C23.8571 26.6666 25.1429 25.3809 25.1429 23.8095V9.52377C25.1429 7.95234 23.8571 6.66663 22.2857 6.66663C20.7143 6.66663 19.4286 7.95234 19.4286 9.52377Z"
            fill="white"
        />
    </svg>
);

export default PauseIcon;
