import EyeIcon from "./EyeIcon";
import InfoIcon from "./InfoIcon";
import SoundIcon from "./SoundIcon";
import SettingIcon from "./SettingIcon";
import CloseIcon from "./CloseIcon";
import CoupeIcon from "./CoupeIcon";
import IncognitoIcon from "./IncognitoIcon";
import BlindCardIcon from "./BlindCardIcon";
import HiddenCardIcon from "./HiddenCardIcon";
import ArrowToLeftIcon from "./ArrowToLeftIcon";
import ArrowToRightIcon from "./ArrowToRightIcon";
import SuitIcon from "./SuitIcon";
import ConnectionLostIcon from "./ConnectionLostIcon";
import PlayIcon from "./PlayIcon";
import NextRoundIcon from "./NextRoundIcon";
import PrevRoundIcon from "./PrevRoundIcon";
import LeaveGameIcon from "./LeaveGameIcon";
import LowConnectionIcon from "./LowConnectionIcon";
import DoubleIcon from "./DoubleIcon";

import Clubs from "./suits/Clubs";
import Hearts from "./suits/Hearts";
import Spades from "./suits/Spades";
import Diamonds from "./suits/Diamonds";
import PauseIcon from "./PauseIcon";
import PrevStepIcon from "./PrevStepIcon";
import NextStepIcon from "./NextStepIcon";

const suits = {
    Clubs,
    Hearts,
    Spades,
    Diamonds
};

export {
    suits,
    EyeIcon,
    InfoIcon,
    SoundIcon,
    SettingIcon,
    CloseIcon,
    CoupeIcon,
    IncognitoIcon,
    BlindCardIcon,
    HiddenCardIcon,
    ArrowToLeftIcon,
    ArrowToRightIcon,
    SuitIcon,
    ConnectionLostIcon,
    PlayIcon,
    NextRoundIcon,
    PrevRoundIcon,
    PauseIcon,
    PrevStepIcon,
    NextStepIcon,
    LeaveGameIcon,
    DoubleIcon,
    LowConnectionIcon
};
